<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-header">
          <el-col>
            <el-tooltip effect="dark" content="添加后期工艺时长" placement="top-start"  v-if="userPermissions.indexOf('craft_add') != -1 && buttonType=='icon'" >
              <el-button type="primary" size="medium" icon="iconfont icon-tianjiacaidan2" @click="addButton()"></el-button>
            </el-tooltip>
            <el-button type="primary" size="medium"  v-if="userPermissions.indexOf('craft_add') != -1 && buttonType=='text'" @click="addButton()">添加</el-button>
          </el-col>
        </el-row>
        <el-row class="table-search">
          <el-form size="medium" :inline="true" :model="searchCondition" class="demo-form-inline">
            <el-form-item label="后期工艺名称">
              <el-input v-model="searchCondition.username" placeholder="输入后期工艺名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchButton">查询</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <ApeTable ref="apeTable" :data="dataList" :loading="loadingStaus" :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
            slot="first-column"
            width="64"
            label="序号">
            <template slot-scope="scope">
              <span>{{offset+scope.$index+1}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="后期工艺名称">
            <template slot-scope="scope">
              <span>{{scope.row.follow_craft_name}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="后期工艺加工阶段公式名称">
            <template slot-scope="scope">
              <span>{{scope.row.follow_craft_phase_name}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="后期工艺阶段一结束点">
            <template slot-scope="scope">
              <span>{{scope.row.follow_craft_phase1}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="后期工艺阶段一价格">
            <template slot-scope="scope">
              <span>{{scope.row.follow_craft_specs_price1}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="后期工艺阶段一结束点">
            <template slot-scope="scope">
              <span>{{scope.row.follow_craft_phase2}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="后期工艺阶段一价格">
            <template slot-scope="scope">
              <span>{{scope.row.follow_craft_specs_price2}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="后期工艺阶段一结束点">
            <template slot-scope="scope">
              <span>{{scope.row.follow_craft_phase3}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="后期工艺阶段一价格">
            <template slot-scope="scope">
              <span>{{scope.row.follow_craft_specs_price3}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="后期工艺描述">
            <template slot-scope="scope">
              <span>{{scope.row.follow_craft_desc}} </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="buttonType=='icon'"
            width="200"
            label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="编辑" placement="top-start"  v-if="userPermissions.indexOf('craft_modify') != -1" >
                <el-button size="medium" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="禁用" placement="top-start"  v-if="userPermissions.indexOf('craft_modify') != -1&&scope.row.craft_status=='1'" >
                <el-button size="medium" icon="el-icon-open" @click="rowSubmit(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="启用"  placement="top-start"  v-if="userPermissions.indexOf('craft_modify') != -1&&scope.row.craft_status=='0'" >
                <el-button size="medium" icon="el-icon-turn-off" @click="rowSubmit(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top-start">
                <span>
                  <el-popover
                    v-if="userPermissions.indexOf('craft_delete') != -1"
                    placement="top"
                    width="150"
                    v-model="scope.row.visible">
                    <p>确定要删除记录吗？</p>
                    <div style="text-align: right; margin: 0;">
                      <el-button type="text" size="medium" @click="scope.row.visible=false">取消</el-button>
                      <el-button type="danger" size="medium" @click="deleteButton(scope.row.id)">确定</el-button>
                    </div>
                    <el-button slot="reference" type="danger" size="medium" icon="el-icon-delete"></el-button>
                  </el-popover>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>

    <ApeDrawer :drawerData="drawerData"  @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-form :model="formData" :rules="rules" ref="dataForm" label-position="right" label-width="96px">
          <el-row>
            <el-col :span="22">
              <el-form-item label="后处理工艺" prop="follow_craft_id">
                <el-select v-model="formData.follow_craft_id" placeholder="请选择">
                  <el-option
                    v-for="item in followCraftList"
                    :key="item.id"
                    :label="item.follow_craft_name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>     
      </template>
    </ApeDrawer>
  </div >
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import { mapGetters } from 'vuex'
import ApeUploader from '@/components/ApeUploader'
export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    ApeUploader
  },
  data() {
    return {
      // 搜索条件
      searchGeneralCondition:{
        current_page: 1,
        page_size: 20,
        query: "",
        params: [],
      },
      searchCondition:{
        username:'',
        mobile:''
      },
      searchFollowCraftCondition: {
        current_page: 1,
        page_size: 200,
        query: "",
        params: [],
      },
      followCraftList:[],
      loadingStaus: true,
      // 表格列表数据
      dataList:[],
      // 分页信息
      pagingData:{
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      // 分页的offset,序号列使用
      offset:0,
      // 表单结构
      formData: {
        id:0
      },
      // 表单验证
      rules: {
        follow_craft_name: [{required: true,message:'输入后期工艺名称', trigger: 'blur' }],
        material_duration_value: [{required: true,message:'输入后期工艺时长描述', trigger: 'blur' }],
      },
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '权限绑定',
        width_height: '580px',
      },
      rolesList: [],
      userRoles: []
      
    }
  },
  computed: {
    ...mapGetters(['userPermissions','buttonType'])
  },
  methods: {
    // 搜索
    searchButton() {
      this.searchGeneralCondition.query= ''
      this.searchGeneralCondition.params = []

      if (this.searchCondition.username != ''){
        this.searchGeneralCondition.query = ' instr(craft_name,?)'
        this.searchGeneralCondition.params.push(this.searchCondition.username)
      }
      if (this.searchGeneralCondition.query !=''){
        if(this.searchCondition.mobile !=''){
          this.searchGeneralCondition.query += ' and instr(craft_year,?)'
          this.searchGeneralCondition.params.push(this.searchCondition.mobile)
        }
      }else{
        this.searchGeneralCondition.query = ' instr(craft_year,?) '
        this.searchGeneralCondition.params.push(this.searchCondition.mobile)
      }
      this.getMaterialFollowCrafts()
    },
    // 切换页码操作
    async switchPaging() {
      this.getMaterialFollowCrafts()
    },
    // 响应添加按钮
    async addButton() {
      this.formData = {}
      this.drawerData.visible = true
      this.drawerData.title = '添加数据'
      this.drawerData.loading = false
    },
    // 响应编辑按钮
    async editButton(id) {
      this.drawerData.loading_text = '玩命加载中……'
      this.drawerData.visible = true
      this.drawerData.title = '编辑数据(UID：'+id+')'
      let {info} = await this.$api.getMaterialFollowCraft(id)
      this.formData = info
      this.formData.follow_craft_id = parseInt(this.formData.follow_craft_id)
      this.drawerData.loading = false
    },
    // 处理抽屉关闭
    drawerClose() {
      this.initFormData()
      this.drawerData.visible = false
      this.drawerData.loading = true
      this.rolesList = []
    },
    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……'
      this.drawerData.loading = true
      let id 
      this.formData.material_id = this.$route.params.id
      if(this.formData.id==undefined){
        id = await this.$api.addMaterialFollowCraft(this.formData)
      }else{
        id = await this.$api.modifyMaterialFollowCraft(this.formData)
      }
      this.$nextTick(() => {
        this.drawerData.visible = false
      })
      this.$nextTick(async () => {
        if (id) {
          this.getMaterialFollowCrafts()
        }
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initFormData()
    },
    // row数据提交，请求接口
    async rowSubmit(row) {
      this.drawerData.loading_text = '玩命提交中……'
      this.drawerData.loading = true
      if(row.craft_status=='0'){
        row.craft_status='1'
      }else{
        row.craft_status='0'
      }
      let id = await this.$api.modifyMaterialFollowCraft(row)
      this.$nextTick(() => {
        this.drawerData.visible = false
      })
      this.$nextTick(async () => {
        if (id) {
          this.getMaterialFollowCrafts()
        }
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initFormData()
    },
    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteMaterialFollowCraft(id)
      if (info.info == null) {
        this.$message.success('删除成功!')
        this.$nextTick(async () => {
          this.getMaterialFollowCrafts()
        })
      } else {
        this.$message.error(info)
      }
      
    },
    // 初始化数据
    initFormData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.formData = {id:0}
        this.$refs['dataForm'].resetFields()
      })
    },
    // 初始化数据列表
    async getMaterialFollowCrafts() {
      this.loadingStaus=true
      this.searchGeneralCondition.material_id = this.$route.params.id
      let {list,list_page_info}= await this.$api.getMaterialFollowCrafts(this.searchGeneralCondition)
      if (list!=null){
          this.dataList=list
      }else{
        this.dataList=[]
      }
      this.pagingData.total = list_page_info.total
      this.offset = list_page_info.page_number
      this.loadingStaus=false
    },
    // 初始化数据列表
    async getFollowCrafts() {
      this.loadingStaus=true
      let {list,pages}= await this.$api.getFollowCrafts(this.searchFollowCraftCondition)
      if (list!=null){
          this.followCraftList=list
      }else{
        this.followCraftList=[]
      }
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStaus=false
    }
  },
  mounted() {
    this.getFollowCrafts()
    this.getMaterialFollowCrafts()
  },
}
</script>

<style lang="stylus">
  .el-button
    margin-right 4px
    margin-bottom 4px
  .table-header
    margin-bottom 12px
  .drag-handle
    font-size 24px
    cursor pointer
  .el-input-group__prepend, .el-input-group__append
    background #ffffff
    padding 0 12px
  .el-input-group__append
    color #ffffff
    background #1890ff
  .el-popover .el-checkbox-group .el-checkbox
    margin-left 0px
    margin-right 12px
  .el-select > .el-input 
    width 300px
</style>
