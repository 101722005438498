<template>
  <div>
    <router-view></router-view>
    <PageHeaderLayout v-show="this.$route.matched.length==4">
      <div class="main-page-content">
        <el-row class="table-header">
          <el-col>
            <el-tooltip effect="dark" content="添加材料" placement="top-start"  v-if="userPermissions.indexOf('material_add') != -1 && buttonType=='icon'" >
              <el-button type="primary" size="medium" icon="iconfont icon-tianjiacaidan2" @click="addButton()"></el-button>
            </el-tooltip>
            <el-button type="primary" size="medium"  v-if="userPermissions.indexOf('material_add') != -1 && buttonType=='text'" @click="addButton()">添加</el-button>
          </el-col>
        </el-row>
        <el-row class="table-search">
          <el-form size="medium" :inline="true" :model="searchCondition" class="demo-form-inline">
            <el-form-item label="材料姓名">
              <el-input v-model="searchCondition.username" placeholder="输入材料名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchButton">查询</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <ApeTable ref="apeTable" :data="dataList" :loading="loadingStaus" :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
            slot="first-column"
            width="64"
            label="序号">
            <template slot-scope="scope">
              <span>{{offset+scope.$index+1}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            width="120"
            label="材料图片">
            <template slot-scope="scope">
              <img :src="scope.row.material_pic" style="width: 64px; height:64px;">
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="材料名称">
            <template slot-scope="scope">
              <span>{{scope.row.material_name}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="材料密度">
            <template slot-scope="scope">
              <span>{{scope.row.material_density}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="抛光系数">
            <template slot-scope="scope">
              <span>{{scope.row.material_polishing_coefficient}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="材料标签">
            <template slot-scope="scope">
              <span>{{scope.row.material_label}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="材料优点">
            <template slot-scope="scope">
              <span>{{scope.row.material_advantage}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="材料最大尺寸">
            <template slot-scope="scope">
              <span>{{scope.row.material_max_size}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="启用">
            <template slot-scope="scope">
              <span v-if="scope.row.status=='0'">停用</span>
              <span v-else-if="scope.row.status=='1'">使用</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="推荐">
            <template slot-scope="scope">
              <span v-if="scope.row.recommend=='0'">推荐</span>
              <span v-else-if="scope.row.recommend=='1'">未推荐</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="材料描述">
            <template slot-scope="scope">
              <span>{{scope.row.material_desc}} </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="buttonType=='icon'"
            width="260"
            label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="编辑" placement="top-start"  v-if="userPermissions.indexOf('material_modify') != -1" >
                <el-button size="medium" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="材料颜色" placement="top-start"  v-if="userPermissions.indexOf('craft_modify') != -1" >
                <el-button size="medium" icon="el-icon-brush" @click="getMaterialColor(scope.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="材料工期" placement="top-start"  v-if="userPermissions.indexOf('craft_modify') != -1" >
                <el-button size="medium" icon="el-icon-time" @click="getMaterialDuration(scope.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="材料后处理工艺" placement="top-start"  v-if="userPermissions.indexOf('craft_modify') != -1" >
                <el-button size="medium" icon="el-icon-guide" @click="getMaterialFollowCraft(scope.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="禁用" placement="top-start"  v-if="userPermissions.indexOf('material_modify') != -1&&scope.row.status=='1'" >
                <el-button size="medium" icon="el-icon-open" @click="rowSubmit(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="启用"  placement="top-start"  v-if="userPermissions.indexOf('material_modify') != -1&&scope.row.status=='0'" >
                <el-button size="medium" icon="el-icon-turn-off" @click="rowSubmit(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top-start">
                <span>
                  <el-popover
                    v-if="userPermissions.indexOf('material_delete') != -1"
                    placement="top"
                    width="150"
                    v-model="scope.row.visible">
                    <p>确定要删除记录吗？</p>
                    <div style="text-align: right; margin: 0;">
                      <el-button type="text" size="medium" @click="scope.row.visible=false">取消</el-button>
                      <el-button type="danger" size="medium" @click="deleteButton(scope.row.id)">确定</el-button>
                    </div>
                    <el-button slot="reference" type="danger" size="medium" icon="el-icon-delete"></el-button>
                  </el-popover>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>

    <ApeDrawer :drawerData="drawerData"  @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-form :model="formData" :rules="rules" ref="dataForm" label-position="right" label-width="96px">
          <el-row>
            <el-col :span="22">
              <el-form-item label="材料名称" prop="material_name">
                <el-input v-model="formData.material_name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="材料密度" prop="material_density">
                <el-input v-model="formData.material_density" type="number"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="材料系数" prop="material_polishing_coefficient">
                <el-input v-model="formData.material_polishing_coefficient"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="材料标签" prop="material_label">
                <el-input v-model="formData.material_label"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="材料优点" prop="material_advantage">
                <el-input v-model="formData.material_advantage"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="最大尺寸" prop="material_max_size">
                <el-input v-model="formData.material_max_size"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="启用" prop="status">
                <el-select v-model="formData.status" placeholder="请选择">
                <el-option
                  v-for="item in opinions"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              </el-form-item>
            
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="推荐" prop="recommend">
                <el-select v-model="formData.recommend" placeholder="请选择">
                <el-option
                  v-for="item in opinions"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="材料描述" prop="material_desc">
                <textarea v-model="formData.material_desc" :style="textareaStyle"></textarea>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="材料图片"> 
                 <ApeUploader :limit="1" @handleUploadSuccess="handleUploadSuccess" @handleUploadRemove="handleUploadRemove" :upload-file-list="uploadFileList"></ApeUploader> 
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>     
      </template>
    </ApeDrawer>
  </div >
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import { mapGetters } from 'vuex'
import ApeUploader from '@/components/ApeUploader'
export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    ApeUploader
  },
  data() {
    return {
      // 搜索条件
      searchGeneralCondition:{
        current_page: 1,
        page_size: 20,
        query: "",
        params: [],
      },
      searchCondition:{
        username:'',
        mobile:''
      },
      textareaStyle: {
        width: '100%',
        height: '160px'
      },
      searchRolesCondition: {
        current_page: 1,
        page_size: 200,
        query: "",
        params: [],
      },
      loadingStaus: true,
      // 表格列表数据
      dataList:[],
      // 分页信息
      pagingData:{
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      // 分页的offset,序号列使用
      offset:0,
      uploadFileList:[],
      // 表单结构
      formData: {
        id:0
      },
      // 表单验证
      rules: {
        material_name: [{required: true,message:'输入材料名称', trigger: 'blur' }],
        material_desc: [{required: true,message:'输入材料描述', trigger: 'blur' }],
      },
      opinions:[
        {
          label:'否',
          value:'0'
        },
        {
          label:'是',
          value:'1'
        }
      ],
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '权限绑定',
        width_height: '580px',
      },
      rolesList: [],
      userRoles: []
      
    }
  },
  computed: {
    ...mapGetters(['userPermissions','buttonType'])
  },
  methods: {
    // 搜索
    searchButton() {
      this.searchGeneralCondition.query= ''
      this.searchGeneralCondition.params = []

      if (this.searchCondition.username != ''){
        this.searchGeneralCondition.query = ' instr(material_name,?)'
        this.searchGeneralCondition.params.push(this.searchCondition.username)
      }
      this.getMaterials()
    },
    // 切换页码操作
    async switchPaging() {
      this.getMaterials()
    },
    // 响应添加按钮
    async addButton() {
      this.uploadFileList=[]
      this.formData = {}
      this.drawerData.visible = true
      this.drawerData.title = '添加数据'
      this.drawerData.loading = false
    },
    // 响应编辑按钮
    async editButton(id) {
      this.uploadFileList=[]
      this.drawerData.loading_text = '玩命加载中……'
      this.drawerData.visible = true
      this.drawerData.title = '编辑数据(UID：'+id+')'
      let {info} = await this.$api.getMaterial(id)
      this.formData = info
      if (info.material_pic !='' ){
        this.uploadFileList = [{url:info.material_pic}]
      }
      this.drawerData.loading = false
    },
    // 处理抽屉关闭
    drawerClose() {
      this.initFormData()
      this.drawerData.visible = false
      this.drawerData.loading = true
      this.rolesList = []
    },
    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // 图片上传成功回调
    handleUploadSuccess(file, fileList) {
      this.formData.material_pic = file
      this.uploadFileList = fileList
    },
    // 图片删除回调
    handleUploadRemove(file, fileList) {
      this.formData.material_pic = 0
      this.uploadFileList = fileList
    },
    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……'
      this.drawerData.loading = true
      let id 
      this.formData.material_category_id = this.$route.params.id
      if(this.formData.id==undefined){
        id = await this.$api.addMaterial(this.formData)
      }else{
        id = await this.$api.modifyMaterial(this.formData)
      }
      this.$nextTick(() => {
        this.drawerData.visible = false
      })
      this.$nextTick(async () => {
        if (id) {
          this.getMaterials()
        }
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initFormData()
    },
    // row数据提交，请求接口
    async rowSubmit(row) {
      this.drawerData.loading_text = '玩命提交中……'
      this.drawerData.loading = true
      if(row.material_status=='0'){
        row.material_status='1'
      }else{
        row.material_status='0'
      }
      let id = await this.$api.modifyMaterial(row)
      this.$nextTick(() => {
        this.drawerData.visible = false
      })
      this.$nextTick(async () => {
        if (id) {
          this.getMaterial()
        }
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initFormData()
    },
    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteMaterial(id)
      if (info == null) {
        this.$message.success('删除成功!')
        this.$nextTick(async () => {
          this.getMaterials()
        })
      } else {
        this.$message.error(info)
      }
      
    },
    // 初始化数据
    initFormData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.formData = {id:0}
        this.$refs['dataForm'].resetFields()
      })
    },
    async getMaterialColor(id) {
       this.$router.push(this.$route.path+'/'+id+'/material_color')
    },
    async getMaterialDuration(id) {
       this.$router.push(this.$route.path+'/'+id+'/material_duration')
    },
    async getMaterialFollowCraft(id) {
       this.$router.push(this.$route.path+'/'+id+'/material_follow_craft')
    },
    // 初始化数据列表
    async getMaterials() {
      this.loadingStaus=true
      if(this.searchGeneralCondition.query == ""){
        this.searchGeneralCondition.query = "material_category_id=?"
        this.searchGeneralCondition.params = [this.$route.params.id] 
      }else{
        this.searchGeneralCondition.query = this.searchGeneralCondition.query + " and material_category_id=?"
        this.searchGeneralCondition.params.push(this.$route.params.id)
      }
      let {list,pages}= await this.$api.getMaterials(this.searchGeneralCondition)
      if (list!=null){
          this.dataList=list
      }else{
        this.dataList=[]
      }
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStaus=false
    }
  },
  mounted() {
    this.getMaterials()
  },
}
</script>

<style lang="stylus">
  .el-button
    margin-right 4px
    margin-bottom 4px
  .table-header
    margin-bottom 12px
  .drag-handle
    font-size 24px
    cursor pointer
  .el-input-group__prepend, .el-input-group__append
    background #ffffff
    padding 0 12px
  .el-input-group__append
    color #ffffff
    background #1890ff
  .el-popover .el-checkbox-group .el-checkbox
    margin-left 0px
    margin-right 12px
  .el-select > .el-input 
    width 300px
</style>
