<template>
  <div>
    <router-view></router-view>
    <PageHeaderLayout v-show="this.$route.matched.length==3">
      <div class="main-page-content">
        <el-row class="table-header">
          <el-col v-if="buttonType=='icon'">
            <el-tooltip effect="dark" content="添加接口" placement="top-start" v-if="userPermissions.indexOf('roles_users') != -1" >
              <el-button type="primary" size="medium" icon="iconfont icon-tianjiacaidan2" @click="addButton(0)"></el-button>
            </el-tooltip>
           
          </el-col>
        </el-row>
        <el-row class="table-search">
          <el-form size="medium" :inline="true" :model="searchCondition" class="demo-form-inline">
            <el-form-item label="名称">
              <el-input v-model="searchCondition.name" placeholder="输入名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchButton">查询</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <ApeTable ref="apeTable" :data="taskList"  :loading="loadingStaus" :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
            slot="first-column"
            width="64"
            label="序号">
            <template slot-scope="scope">
              <span>{{scope.row.entry_id}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="接口标识">
            <template slot-scope="scope">
              <span>{{scope.row.app_api_flag}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="接口动作">
            <template slot-scope="scope">
              <span>{{scope.row.app_api_action}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="接口描述">
            <template slot-scope="scope">
              <span>{{scope.row.app_api_desc}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.app_api_item_status==1">启用</span>
              <span v-else>停用</span>
            </template>
          </el-table-column>
          <el-table-column
          label="操作">
            <template slot-scope="scope">
              <span>
                <el-tooltip effect="dark" content="编辑" placement="top-start"  v-if="userPermissions.indexOf('roles_edit') != -1" >
                  <el-button size="medium" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="流程模版" placement="top-start"  v-if="userPermissions.indexOf('roles_users') != -1" >
                  <el-button size="medium" icon="el-icon-s-platform" @click="manageTaskItemArgsTemplates(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="删除" placement="top-start">
                  <span>
                    <el-popover
                      v-if="userPermissions.indexOf('roles_delete') != -1"
                      placement="top"
                      width="150"
                      v-model="scope.row.visible">
                      <p>确定要删除记录吗？</p>
                      <div style="text-align: right; margin: 0;">
                        <el-button type="text" size="medium" @click="scope.row.visible=false">取消</el-button>
                        <el-button type="danger" size="medium" @click="deleteButton(scope.row.id)">确定</el-button>
                      </div>
                      <el-button slot="reference" type="danger" size="medium" icon="el-icon-delete"></el-button>
                    </el-popover>
                  </span>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
          
        </ApeTable>
      </div>
    </PageHeaderLayout>
    <ModalDialog :dialogData="dialogData" @dialogConfirm="handleConfirm" @dialogClose="dialogClose">
      <template slot="content">
        <el-form :model="formData" :rules="rules" ref="taskApeDrawerForm" label-position="right" label-width="96px">
          <el-form-item label="接口标识" prop="app_api_flag">
            <el-input v-model="formData.app_api_flag"></el-input>
          </el-form-item>
          <el-form-item label="接口动作" prop="app_api_action">
            <el-input v-model="formData.app_api_action"></el-input>
          </el-form-item>
          <el-form-item label="接口状态" prop="app_api_item_status">
            <el-select v-model="formData.app_api_item_status" placeholder="请选择">
              <el-option
                v-for="item in taskStatus"
                :key="item.label"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>        
      </template>
    </ModalDialog>
  </div >
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ModalDialog from '@/components/ModalDialog'
import { mapGetters } from 'vuex'

export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ModalDialog
  },
  data() {
    return {
      loadingStaus: true,
      dialogData:{
        visible: false,
        title: '',
        width: '560px',
        loading: true,
        loading_text: '',
        modal: true
      },
      searchCondition:{
        name:'',
      },
      taskStatus:[
        {
          label:'停用',
          value:'0'
        },
        {
          label:'使用',
          value:'1'
        },
      ],
      // 表格列表数据
      taskList:[],
      // 分页信息
      pagingData:{
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      searchTaskItemCondition:{
        "current_page": 1,
        "page_size": 200,
        "query":"entry_id=?",
        "params":[]
      },
      // 分页的offset,序号列使用
      offset:0,
      // 表单结构
      formData: {id:0,entry_id:0},
      // 表单验证
      rules: {
        mobile: [
          {required: true, message: '选择用户', trigger: 'blur', validator:this.autocompleteVerify },
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['userPermissions','buttonType'])
  },
  methods: {
    // 切换页码操作
    searchButton() {
      this.initTaskItemList()
    },
    async switchPaging() {
       this.initTaskItemList()
    },
    // 响应添加按钮
    async addButton() {
      this.formData.id = 0
      this.dialogData.visible = true
      this.dialogData.title = '添加接口'
      this.dialogData.loading = false
    },
    // 响应编辑按钮
    async editButton(id) {
      this.dialogData.visible = true
      this.dialogData.title = '编辑组'
      this.dialogData.eidit=true
      let {info} = await this.$api.getAppApiEntryItem(id)
      this.formData = info
      this.dialogData.loading = false
    },
    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteAppApiEntryItem(id)
      if (info.id == id) {
        let {list}= await this.$api.getAppApiEntryItemList(this.searchTaskItemCondition)
        this.taskList = list
        this.$message.success('删除成功!')
      } else {
        this.$message.error(info)
      }
    },
    // 处理模态框，确定事件
    handleConfirm() {
      // 调用组件的数据验证方法
      this.$refs['taskApeDrawerForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // 处理模态框，关闭事件
    dialogClose() {
      this.initFormData()
    },
    // form数据提交，请求接口
    async formSubmit() {
      let ok = false
      this.dialogData.loading_text = '玩命提交中……'
      this.dialogData.loading = true
      this.formData.entry_id=parseInt(this.$route.params.entry_id)
      ok = await this.$api.storeAppApiEntryItem(this.formData)
      this.$nextTick(() => {
        this.dialogData.visible = false
      })
      this.$nextTick(async () => {
          this.initTaskItemList()
      })
      if(ok){
        this.$nextTick(() => {
          this.$message.success('保存成功!')
        })
      }
      this.initFormData()
    },
    // 初始化数据
    initFormData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.dialogData.visible = false
        this.dialogData.loading = true
        this.$refs['taskApeDrawerForm'].resetFields()
      })
    },
    // 初始化用户列表
    async initTaskItemList() {
      this.loadingStaus=true
      this.searchTaskItemCondition.params=[]
      this.searchTaskItemCondition.query = 'entry_id=?'
      let pagingInfo = this.$refs['apeTable'].getPagingInfo()
      // 实现对量合并
      Object.assign(this.searchTaskItemCondition,pagingInfo)
      this.formData.entry_id=parseInt(this.$route.params.entry_id)
      this.searchTaskItemCondition.params.push(parseInt(this.$route.params.entry_id))
      if (this.searchCondition.name != ''){
        this.searchTaskItemCondition.query = this.searchTaskItemCondition.query + ' and instr(app_api_flag,?)'
        this.searchTaskItemCondition.params.push(this.searchCondition.name)
      }
      let {list,pages}= await this.$api.getAppApiEntryItemList(this.searchTaskItemCondition)
      if(list==null){
        this.taskList=[]
      }else{
        this.taskList=list
      }
      if(pagingInfo.current_page ===1){
         this.pagingData.total = pages.total
         this.offset = pages.offset
      } 
      this.loadingStaus=false
    },
    // 管理接口校验
    manageTaskItemVerify(row) {
      this.$router.push(this.$route.path+'/'+row.id+'/verify')
    },
    manageTaskItemArgsTemplates(row) {
      this.$router.push(this.$route.path+'/'+row.id+'/args_template')
    },
    manageTaskItemResultTemplates(row) {
      this.$router.push(this.$route.path+'/'+row.id+'/result_template')
    },
    manageTaskItemErrMessage(row) {
      this.$router.push(this.$route.path+'/'+row.id+'/err_message')
    },
    // 管理接口校验
    manageTaskItemFunc(row) {
    
      this.$router.push(this.$route.path+'/'+row.id+'/func')
    },
    // 管理接口配置
    manageTaskItemConfig(row) {
      
      this.$router.push(this.$route.path+'/'+row.id+'/config')
    },  
  },
  mounted() {
    this.initTaskItemList()
  },
}
</script>

<style lang="stylus">
  .el-button
    margin-right 4px
    margin-bottom 4px
  .table-header
    margin-bottom 12px
  .drag-handle
    font-size 24px
    cursor pointer
  .el-input-group__prepend, .el-input-group__append
    background #ffffff
    padding 0 12px
  .el-input-group__append
    color #ffffff
    background #1890ff
  .el-popover .el-checkbox-group .el-checkbox
    margin-left 0px
    margin-right 12px
  .el-select > .el-input 
    width 300px
  .fanhui-button
    float right 
</style>
