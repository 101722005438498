<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-header">
        </el-row>
        <el-row class="table-search">
          <el-form size="medium" :inline="true" :model="searchCondition" class="demo-form-inline">
            <el-form-item label="订单文件名称">
              <el-input v-model="searchCondition.username" placeholder="输入订单文件名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchButton">查询</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <ApeTable ref="apeTable" :data="userList" :loading="loadingStaus" :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
            slot="first-column"
            width="64"
            label="序号">
            <template slot-scope="scope">
              <span>{{offset+scope.$index+1}}</span>
            </template>
          </el-table-column>
           <el-table-column
            slot="second-column"
            label="文件名称">
            <template slot-scope="scope">
              <span>{{scope.row.filename}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="文件位置">
            <template slot-scope="scope">
              <span>{{scope.row.filepath}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="文件格式">
            <template slot-scope="scope">
              <span>{{scope.row.fileformat}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="文件体积">
            <template slot-scope="scope">
              <span>{{scope.row.filevolume}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="文件高">
            <template slot-scope="scope">
              <span>{{scope.row.filehight}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="文件长">
            <template slot-scope="scope">
              <span>{{scope.row.filelenght}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="文件宽">
            <template slot-scope="scope">
              <span>{{scope.row.filewithd}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="文件表面积">
            <template slot-scope="scope">
              <span>{{scope.row.filevolumearea}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="上传时间">
            <template slot-scope="scope">
              <span>{{scope.row.upload_at}} </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="buttonType=='icon'"
            width="200"
            label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="删除" placement="top-start">
                <span>
                  <el-popover
                    v-if="userPermissions.indexOf('department_delete') != -1"
                    placement="top"
                    width="150"
                    v-model="scope.row.visible">
                    <p>确定要删除记录吗？</p>
                    <div style="text-align: right; margin: 0;">
                      <el-button type="text" size="medium" @click="scope.row.visible=false">取消</el-button>
                      <el-button type="danger" size="medium" @click="deleteButton(scope.row.id)">确定</el-button>
                    </div>
                    <el-button slot="reference" type="danger" size="medium" icon="el-icon-delete"></el-button>
                  </el-popover>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>
  </div >
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import { mapGetters } from 'vuex'
import ApeUploader from '@/components/ApeUploader'
export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    ApeUploader
  },
  data() {
    return {
      // 搜索条件
      searchCustomerOrderFileCondition:{
        current_page: 1,
        page_size: 20,
        query: "customer_id=?",
        params: [],
      },
      searchCondition:{
        username:'',
        mobile:''
      },
      searchRolesCondition: {
        current_page: 1,
        page_size: 200,
        query: "",
        params: [],
      },
      loadingStaus: true,
      uploadFileList:[],
      // 表格列表数据
      userList:[],
      // 分页信息
      pagingData:{
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      // 分页的offset,序号列使用
      offset:0,
      // 表单结构
      formData: {
        avatar:''
      },
      // 表单验证
      rules: {
        department_name: [{required: true,message:'选择语言版本', trigger: 'blur' }],
        department_desc: [{required: true,message:'输入订单文件名称', trigger: 'blur' }],
      },
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '权限绑定',
        width_height: '580px',
      },
      rolesList: [],
      userRoles: []
      
    }
  },
  computed: {
    ...mapGetters(['userPermissions','buttonType'])
  },
  methods: {
    // 搜索
    searchButton() {
      this.searchCustomerOrderFileCondition.query= ''
      this.searchCustomerOrderFileCondition.params = []

      if (this.searchCondition.username != ''){
        this.searchCustomerOrderFileCondition.query = ' instr(filename,?)'
        this.searchCustomerOrderFileCondition.params.push(this.searchCondition.username)
      }
      if (this.searchCustomerOrderFileCondition.query !=''){
        if(this.searchCondition.mobile !=''){
          this.searchCustomerOrderFileCondition.query += ' and instr(department_name,?)'
          this.searchCustomerOrderFileCondition.params.push(this.searchCondition.mobile)
        }
      }
      this.initCustomerOrderFileList()
    },
    // 切换页码操作
    async switchPaging() {
      this.initCustomerOrderFileList()
    },
    // 响应添加按钮
    async addButton() {
      this.uploadFileList=[]
      this.formData = {}
      this.drawerData.visible = true
      this.drawerData.title = '添加订单文件'
      this.drawerData.loading = false
    },
    // 响应编辑按钮
    async editButton(id) {
      this.uploadFileList=[]
      this.drawerData.loading_text = '玩命加载中……'
      this.drawerData.visible = true
      this.drawerData.title = '编辑用户(UID：'+id+')'
      let {info} = await this.$api.getCustomerOrderFile(id)
      this.formData = info
      this.drawerData.loading = false
    },
    // 处理抽屉关闭
    drawerClose() {
      this.initFormData()
      this.drawerData.visible = false
      this.drawerData.loading = true
      this.rolesList = []
    },
    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……'
      this.drawerData.loading = true
      let id 
      if (this.formData.id==null || this.formData.id==0){
          id = await this.$api.addCustomerOrderFile(this.formData)
      }else{
          id = await this.$api.modifyCustomerOrderFile(this.formData)
      }
      this.$nextTick(() => {
        this.drawerData.visible = false
      })
      this.$nextTick(async () => {
        if (id) {
          this.initCustomerOrderFileList()
        }
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initFormData()
    },
    // row数据提交，请求接口
    async rowSubmit(row) {
      this.drawerData.loading_text = '玩命提交中……'
      this.drawerData.loading = true
      let id = await this.$api.addCustomerOrderFile(row)
      this.$nextTick(() => {
        this.drawerData.visible = false
      })
      this.$nextTick(async () => {
        if (id) {
          this.initCustomerOrderFileList()
        }
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initFormData()
    },
    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteCustomerOrderFile(id)
      if (info==null) {
        this.$nextTick(async () => {
          this.initCustomerOrderFileList()
        })
      } else {
        this.$message.error(info)
      }
      
    },
    // 初始化数据
    initFormData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.formData = {user_role:{system_role_id:0}}
        this.$refs['userForm'].resetFields()
      })
    },
    // 图片上传成功回调
    handleUploadSuccess(file, fileList) {
      this.formData.staff_image = file
      this.uploadFileList = fileList
    },
    // 图片删除回调
    handleUploadRemove(file, fileList) {
      this.formData.staff_image = 0
      this.uploadFileList = fileList
    },
    // 初始化用户列表
    async initCustomerOrderFileList() {
      this.loadingStaus=true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo()
      Object.assign(pagingInfo,this.$route.params)
      this.searchCustomerOrderFileCondition.params=[parseInt(pagingInfo.id)]
      this.formData.customer_id = parseInt(pagingInfo.id)
      let {list,pages}= await this.$api.getCustomerOrderFileList(this.searchCustomerOrderFileCondition)
      if(list==null){
        this.userList=[]
      }else{
        this.userList=list
      }
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStaus=false
    }
  },
  mounted() {
    this.initCustomerOrderFileList()
  },
}
</script>

<style lang="stylus">
  .el-button
    margin-right 4px
    margin-bottom 4px
  .table-header
    margin-bottom 12px
  .drag-handle
    font-size 24px
    cursor pointer
  .el-input-group__prepend, .el-input-group__append
    background #ffffff
    padding 0 12px
  .el-input-group__append
    color #ffffff
    background #1890ff
  .el-popover .el-checkbox-group .el-checkbox
    margin-left 0px
    margin-right 12px
  .el-select > .el-input 
    width 300px
</style>
