<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-header">
          <el-col v-if="buttonType=='icon'">
            <el-tooltip effect="dark" content="添加成员" placement="top-start" v-if="userPermissions.indexOf('roles_users') != -1" >
              <el-button type="primary" size="medium" icon="iconfont icon-tianjiacaidan2" @click="addButton(0)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="返回" placement="top-start">
              <el-button class="fanhui-button" type="primary" size="medium" icon="iconfont icon-fanhui4" @click="returnRolesList"></el-button>
            </el-tooltip>
          </el-col>
          <el-col v-if="buttonType=='text'">
            <el-button type="primary" size="medium" v-if="userPermissions.indexOf('roles_users') != -1" @click="addButton(0)">添加成员</el-button>
            <el-button class="fanhui-button" type="primary" size="medium" @click="returnRolesList">返回</el-button>
          </el-col>
        </el-row>
        <ApeTable ref="apeTable" :data="userList"  :loading="loadingStaus" :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
            slot="first-column"
            width="64"
            label="序号">
            <template slot-scope="scope">
              <span>{{offset+scope.$index+1}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            width="120"
            label="头像">
            <template slot-scope="scope">
              <img :src="scope.row.Item.avatar" style="width: 64px; height:64px;">
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="状态">
            <template slot-scope="scope">
               <span v-if="scope.row.customer_type=='0'">待处理</span>
               <span v-else-if="scope.row.customer_type=='1'">同意</span>
               <span v-else>拒绝</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="类别">
            <template slot-scope="scope">
               <span v-if="scope.row.customer_type=='0'">被邀请</span>
               <span v-else-if="scope.row.customer_type=='1'">申请</span>
               <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="用户名称">
            <template slot-scope="scope">
              <span>{{scope.row.Item.last_name + " " + scope.row.Item.first_name}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="用户邮箱">
            <template slot-scope="scope">
              <span>{{scope.row.Item.email}} </span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="用户电话">
            <template slot-scope="scope">
              <span>{{scope.row.Item.mobile}} </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="buttonType=='icon'"
            label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="移除成员" placement="top-start">
                <span>
                  <el-popover
                    v-if="userPermissions.indexOf('roles_users') != -1"
                    placement="top"
                    width="150"
                    v-model="scope.row.visible">
                    <p>确定移除该用户吗？</p>
                    <div style="text-align: right; margin: 0;">
                      <el-button type="text" size="medium" @click="scope.row.visible=false">取消</el-button>
                      <el-button type="danger" size="medium" @click="removeButton(scope.row)">确定</el-button>
                    </div>
                    <el-button slot="reference" type="danger" size="medium" icon="iconfont icon-yichu-"></el-button>
                  </el-popover>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            v-if="buttonType=='text'"
            label="操作">
            <template slot-scope="scope">
              <span>
                <el-popover
                  v-if="userPermissions.indexOf('roles_users') != -1"
                  placement="top"
                  width="150"
                  v-model="scope.row.visible">
                  <p>确定移除该用户吗？</p>
                  <div style="text-align: right; margin: 0;">
                    <el-button type="text" size="medium" @click="scope.row.visible=false">取消</el-button>
                    <el-button type="danger" size="medium" @click="removeButton(scope.row)">确定</el-button>
                  </div>
                  <el-button slot="reference" type="danger" size="medium">移除成员</el-button>
                </el-popover>
              </span>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>

    <ModalDialog :dialog-data="dialogData" @dialogConfirm="handleConfirm" @dialogClose="dialogClose">
      <template slot="content">
        <el-form :model="formData" :rules="rules" ref="rolesUserForm" label-position="right" label-width="80px">
          <el-form-item label="选择用户" prop="mobile">
            <el-autocomplete
              v-model="formData.mobile"
              :fetch-suggestions="searchUser"
              placeholder="输入工号检索用户"
              @select="handleUserSelect"
              @clear="initMobileVerify"
              clearable>
            </el-autocomplete>
          </el-form-item>
        </el-form>        
      </template>
    </ModalDialog>
  </div >
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ModalDialog from '@/components/ModalDialog'
import { mapGetters } from 'vuex'

export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ModalDialog
  },
  data() {
    return {
      loadingStaus: true,
      dialogData:{
        visible: false,
        title: '',
        width: '360px',
        loading: true,
        loading_text: '',
        modal: true
      },
      // 表格列表数据
      userList:[],
      // 分页信息
      pagingData:{
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      searchCustomerGroupUserCondition:{
        "current_page": 1,
        "page_size": 200,
        "query":"customer_group_id=?",
        "params":[]
      },
      // 分页的offset,序号列使用
      offset:0,
      // 表单结构
      formData: {id:''},
      // 表单验证
      rules: {
        mobile: [
          {required: true, message: '选择用户', trigger: 'blur', validator:this.autocompleteVerify },
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['userPermissions','buttonType'])
  },
  methods: {
    // 切换页码操作
    async switchPaging() {
      this.initUserList()
    },
    // 响应添加按钮
    async addButton() {
      this.dialogData.visible = true
      this.dialogData.title = '添加成员'
      this.dialogData.loading = false
    },
    // 搜索用户,qs请求参数，cb回调函数，详见组件用法
    async searchUser(qs, cb) {
      if(qs==null){
        qs = ""
      }
      let params={
          query:"instr(first_name,?) or instr(last_name,?) or instr(email,?) or instr(mobile,?)",
          params:[qs,qs,qs,qs]
      }
      let {list} = await this.$api.getCustomers(params)
      for (let i = 0, len = list.length; i < len; i++) {
          list[i].value = list[i].mobile;           
      }
      cb(list)
      
    },
    // 处理搜索结果的选中
    handleUserSelect(item) {
      this.formData = item
      if (this.formData) {
        this.$refs['rolesUserForm'].validateField('mobile')
      }
    },
    // 初始化验证
    initMobileVerify() {
      this.formData.id = ''
      this.$refs['rolesUserForm'].validateField('mobile')
    },
    // 处理模态框，确定事件
    handleConfirm() {
      // 调用组件的数据验证方法
      this.$refs['rolesUserForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // 处理模态框，关闭事件
    dialogClose() {
      this.initFormData()
    },
    // form数据提交，请求接口
    async formSubmit() {
      this.dialogData.loading_text = '玩命提交中……'
      this.dialogData.loading = true
      let data = {customer_group_id:parseInt(this.$route.params.id),customer_id:this.formData.id}
      await this.$api.addCustomerGroupUser(data)
      this.$nextTick(() => {
        this.dialogData.visible = false
      })
      this.$nextTick(async () => {
          this.initUserList()
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initFormData()
    },
    // 相应移除按钮
    async removeButton(row) {
      let info = await this.$api.deleteCustomerGroupUser(row.id)
      if (info==null) {
        this.$nextTick(async () => {
          this.initUserList('del')
        })
      } else {
        this.$message.error(info)
      } 
    },
    // 初始化数据
    initFormData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.dialogData.visible = false
        this.dialogData.loading = true
        this.formData = {user_id:''}
        this.$refs['rolesUserForm'].resetFields()
      })
    },
    // 初始化用户列表
    async initUserList(type) {
      this.loadingStaus=true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      Object.assign(pagingInfo,this.$route.params)
      this.searchCustomerGroupUserCondition.params=[parseInt(pagingInfo.id)]
      this.formData.customer_group_id = parseInt(pagingInfo.id)
      let {list,pages}= await this.$api.getCustomerGroupUsers(this.searchCustomerGroupUserCondition)
      this.userList=list
      if(pagingInfo.current_page ===1){
         this.pagingData.total = pages.total
         this.offset = pages.offset
      } 
      this.loadingStaus=false
    },
    // 搜索用户，自动补全验证
    autocompleteVerify(rule, value, callback) {
      if (rule.required && (!this.formData.id || !this.formData.mobile) ) {
        callback(new Error(rule.message))
      }
      callback()
    },
    // 返回角色列表
    returnRolesList() {
      this.$router.push(this.$route.matched[1].path)
    }
    
  },
  mounted() {
    this.initUserList()
  },
}
</script>

<style lang="stylus">
  .el-button
    margin-right 4px
    margin-bottom 4px
  .table-header
    margin-bottom 12px
  .drag-handle
    font-size 24px
    cursor pointer
  .el-input-group__prepend, .el-input-group__append
    background #ffffff
    padding 0 12px
  .el-input-group__append
    color #ffffff
    background #1890ff
  .el-popover .el-checkbox-group .el-checkbox
    margin-left 0px
    margin-right 12px
  .el-select > .el-input 
    width 300px
  .fanhui-button
    float right 
</style>
