<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="table-header">
          <el-col :span="3">
            <el-tooltip effect="dark" content="命令模板" placement="top-start"  v-if="userPermissions.indexOf('roles_users') != -1">
              <el-button type="primary" size="medium" icon="iconfont icon-tianjiacaidan2" @click="addButton()"></el-button>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-row class="table-search">
          <el-form size="medium" :inline="true" :model="searchCondition" class="demo-form-inline">
            <el-form-item label="名称">
              <el-input v-model="searchCondition.name" placeholder="输入名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchButton">查询</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <ApeTable ref="apeTable" :data="ItemFuncList"  :loading="loadingStaus" :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
            slot="first-column"
            width="64"
            label="序号">
            <template slot-scope="scope">
              <span>{{offset+scope.$index+1}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="模版key">
            <template slot-scope="scope">
              <span>{{scope.row.template_key}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            width="960"
            label="模版值">
            <template slot-scope="scope">
              <span>{{scope.row.template_value}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="模版描述">
            <template slot-scope="scope">
              <span>{{scope.row.template_desc}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            label="模版执行结果">
            <template slot-scope="scope">
              <span v-if="scope.row.is_singular=='0'">多条</span>
              <span v-else-if="scope.row.is_singular=='1'">单条</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="buttonType=='icon'"
            width="200"
            label="操作">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="编辑" placement="top-start"   >
                <el-button size="medium" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="删除" placement="top-start">
                <span>
                  <el-popover
                    placement="top"
                    width="150"
                    v-model="scope.row.visible">
                    <p>确定要删除记录吗？</p>
                    <div style="text-align: right; margin: 0;">
                      <el-button type="text" size="medium" @click="scope.row.visible=false">取消</el-button>
                      <el-button type="danger" size="medium" @click="deleteButton(scope.row.id)">确定</el-button>
                    </div>
                    <el-button slot="reference" type="danger" size="medium" icon="el-icon-delete"></el-button>
                  </el-popover>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>
    <ModalDialog :dialogData="dialogData" @dialogConfirm="handleConfirm" @dialogClose="dialogClose">
      <template slot="content">
        <el-form :model="formData" :rules="rules" ref="dataForm" label-position="right" label-width="96px">
          <el-form-item label="模板key" prop="template_key">
            <el-input v-model="formData.template_key"></el-input>
          </el-form-item>
          <el-form-item label="模板值" prop="template_value">
            <textarea v-model="formData.template_value" :style="textareaStyle"></textarea>
          </el-form-item>
          <el-form-item label="模板描述" prop="template_desc">
            <el-input v-model.number="formData.template_desc"></el-input>
          </el-form-item>
          <el-form-item label="结果条数" prop="is_singular">
            <el-select v-model="formData.is_singular" placeholder="请选择">
              <el-option
                v-for="item in is_singulars"
                :key="item.label"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>        
      </template>
    </ModalDialog>
  </div >
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ModalDialog from '@/components/ModalDialog'
import { mapGetters } from 'vuex'
import ApeUploader from '@/components/ApeUploader'
export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ModalDialog,
    ApeUploader
  },
  data() {
    return {
      // 搜索条件
      searchConfigCondition:{
        current_page: 1,
        page_size: 20,
        query: "item_id",
        params: [0],
      },
      textareaStyle: {
        width: '100%',
        height: '360px'
      },
      uploadHeaders:{},
      searchCondition:{
        name:''
      },
      searchItemFuncCondition:{
        "current_page": 1,
        "page_size": 99,
        "query":"item_id=?",
        "params":[]
      },
      loadingStaus: true,
      ItemFuncList:[],
      appApiEntryItemResultTemplateList:[],
      // 分页信息
       pagingData:{
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        page_size: 20,
        total: 0
      },
      // 分页的offset,序号列使用
      offset:0,
      // 表单结构
      formData: {id:0},
      searchSourceCondition:{
        "current_page": 1,
        "page_size": 999,
        "query":"",
        "params":[""]
      },
      searchTaskParamCondition:{
        "current_page": 1,
        "page_size": 999,
        "query":"",
        "params":[""]
      },
      selectCategories:[],
      selectPRules:[],
      // 表单验证
      rules: {
        
      },
      is_singulars:[
        {
          label:'多条',
          value:'0'
        },
        {
          label:'单条',
          value:'1'
        }
      ],
      // 抽屉数据
      dialogData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '权限绑定',
        width_height: '100%',
      },
    }
  },
  computed: {
    ...mapGetters(['userPermissions','buttonType'])
  },
  methods: {
    // 搜索
    searchButton() {
      this.initAppApiEntryItemResultTemplateList()
    },
    // 切换页码操作
    async switchPaging() {
      this.initAppApiEntryItemResultTemplateList()
    },
    async setDesc(key){
      const obj = this.appApiEntryItemResultTemplateList.find(item=> item.param_key == key)
      this.formData.func_desc=obj.param_desc
      console.log(obj)
    },  
    // 响应添加按钮
    async addButton() {
      this.formData.id = 0
      this.dialogData.visible = true
      this.dialogData.title = '添加组'
      this.dialogData.loading = false
      this.dialogData.eidit=false
    },
    // 响应编辑按钮
    async editButton(id) {
      this.dialogData.loading_text = '玩命加载中……'
      this.dialogData.visible = true
      this.dialogData.title = '编辑目标(UID：'+id+')'
      let {info} = await this.$api.getAppApiEntryItemResultTemplate(id)
      this.formData = info
      this.dialogData.loading = false
    },
    // form数据提交，请求接口
    async formSubmit() {
      this.dialogData.loading_text = '玩命提交中……'
      this.dialogData.loading = true
      if(this.formData.config_rule_id ==''){
        this.formData.config_rule_id = 0
      }
      let ok=await this.$api.storeAppApiEntryItemResultTemplate(this.formData)
      if(ok){
        this.$nextTick(async () => {
          this.dialogData.visible = false
          this.initAppApiEntryItemResultTemplateList()
          this.$message.success('保存成功!')
        })   
      }
      this.initFormData()
    },
    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteAppApiEntryItemResultTemplate(id)
      if (info==null) {
        this.$nextTick(async () => {
          this.initAppApiEntryItemResultTemplateList()
          this.$message.success('删除成功')
        })
      } else {
        this.$message.error(info)
      }
      
    },
     // 处理模态框，确定事件
     handleConfirm() {
      // 调用组件的数据验证方法
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // 处理模态框，关闭事件
    dialogClose() {
      this.initFormData()
    },
    // 初始化数据
    initFormData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.dialogData.visible = false
        this.dialogData.loading = true
        this.$refs['dataForm'].resetFields()
      })
    },
    // 初始化目标列表
    async initAppApiEntryItemResultTemplateList() {
      this.loadingStaus=true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo()
      Object.assign(this.searchItemFuncCondition,pagingInfo)
      this.searchItemFuncCondition.query = 'item_id=?'
      this.formData.item_id=parseInt(this.$route.params.item_id)
      this.searchItemFuncCondition.params=[]
      this.searchItemFuncCondition.params.push(parseInt(this.$route.params.item_id))
      if (this.searchCondition.name != ''){
        this.searchItemFuncCondition.query = this.searchItemFuncCondition.query + ' and instr(template_key,?)'
        this.searchItemFuncCondition.params.push(this.searchCondition.name)
      }
      let {list,pages}= await this.$api.getAppApiEntryItemResultTemplateList(this.searchItemFuncCondition)
      this.ItemFuncList = []
      this.$nextTick(() => {
        if(list){
          this.ItemFuncList = list
        }
      })
      if(pagingInfo.current_page == 1){
        this.pagingData.total = pages.total
      }
      this.loadingStaus=false
    }
  },
  mounted() {
    this.initAppApiEntryItemResultTemplateList()
    
  },
}
</script>

<style lang="stylus">
  .el-button
    margin-right 4px
    margin-bottom 4px
  .table-header
    margin-bottom 12px
  .drag-handle
    font-size 24px
    cursor pointer
  .el-input-group__prepend, .el-input-group__append
    background #ffffff
    padding 0 12px
  .el-input-group__append
    color #ffffff
    background #1890ff
  .el-popover .el-checkbox-group .el-checkbox
    margin-left 0px
    margin-right 12px
  .el-select > .el-input 
    width 300px
</style>
